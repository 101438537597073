<template>
	<v-container
		id="RoundList"
		fluid
		tag="section">
		<v-card class="v-card--material pa-3 px-5 py-3">
			<CardHeading :title="'Round List'">
				<div class="d-flex align-center">
					<v-text-field
						clearable
						v-model="search"
						prepend-icon="mdi-magnify"
						class="mr-5"></v-text-field>

					<v-tooltip
						bottom
						color="primary darken-2">
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								elevation="1"
								fab
								small
								v-show="!roundSearch.isLoading && roundSearch.data.length > 0"
								v-on="tooltip"
								color="primary"
								@click="onExportOpen">
								<v-icon>mdi-arrow-down-bold-circle</v-icon>
							</v-btn>
						</template>
						<span>Export rounds</span>
					</v-tooltip>
				</div>
			</CardHeading>
			<v-card-text>
				<v-data-table
					must-sort
					:options.sync="options"
					:item-class="itemClass"
					:loading="roundSearch.isLoading"
					sort-by="start_time"
					:sort-desc="true"
					:footer-props="footerProps"
					:headers="filteredHeaders"
					:items="filteredData"
					@click:row="handleRowClick"
					class="elevation-1">
					<template #top="{ pagination }">
						<v-data-footer
							class="anton"
							:options.sync="options"
							v-bind="footerProps"
							:pagination="pagination"></v-data-footer>
					</template>
					<template #[`item.copy`]="{ item }">
						<v-btn
							@click="(e) => copyLine(e, item)"
							fab
							text
							x-small>
							<v-icon color="kajot-text">mdi-content-copy</v-icon>
						</v-btn>
					</template>
					<template #[`item.flags`]="{ item }">
						<template v-for="flag of item.flags">
							<v-tooltip
								bottom
								v-if="ROUND_FLAGS[flag]"
								:key="flag">
								<template #activator="{ on, attrs }">
									<v-avatar
										size="30"
										v-on="on"
										v-bind="attrs"
										color="primary"
										class="mx-1">
										<span class="font-weight-bold kajot-anti-text--text">
											{{ ROUND_FLAGS[flag].title }}
										</span>
									</v-avatar>
								</template>
								<span>{{ ROUND_FLAGS[flag].tooltip }}</span>
							</v-tooltip>
						</template>
					</template>
					<template #[`item.external_round`]="{ item }">
						<span
							v-html="searchInId(item.external_round)"
							:title="
								shouldShortenString(item.external_round)
									? item.external_round
									: undefined
							"></span>
					</template>
					<template #[`item.external_bet`]="{ item }">
						<span
							v-html="searchInId(item.external_bet)"
							:title="
								shouldShortenString(item.external_bet)
									? item.external_bet
									: undefined
							"></span>
					</template>
					<template #[`item.external_win`]="{ item }">
						<span
							v-html="searchInId(item.external_win)"
							:title="
								shouldShortenString(item.external_win)
									? item.external_win
									: undefined
							"></span>
					</template>

					<template #[`item.start_time`]="{ item }">
						<span
							v-html="
								$options.filters.highlight(
									$options.filters.Date(item.start_time),
									search
								)
							"></span>
					</template>
					<template #[`item.round_id`]="{ item }">
						<span
							v-html="$options.filters.highlight(item.round_id, search)"></span>
					</template>
					<template #[`item.bet`]="{ item }">
						<span
							v-html="
								$options.filters.highlight(
									$options.filters.Number(item.bet, {
										currency: item.currency,
									}),
									search
								)
							"></span>
					</template>
					<template #[`item.win`]="{ item }">
						<span
							v-html="
								$options.filters.highlight(
									$options.filters.Number(item.win, {
										currency: item.currency,
									}),
									search
								)
							"></span>
					</template>
					<template #[`item.netto`]="{ item }">
						<span
							v-html="
								$options.filters.highlight(
									$options.filters.Number(item.netto, {
										currency: item.currency,
									}),
									search
								)
							"></span>
					</template>
					<template #[`item.match_key`]="{ item }">
						<span
							v-html="
								$options.filters.highlight(
									$options.filters.Capitalize(item.match_key.replace('_', ' ')),
									search
								)
							"></span>
					</template>

					<template #[`item.finished`]="{ item }">
						<v-icon
							color="primary lighten-1"
							v-if="item.finished">
							mdi-check
						</v-icon>
						<v-icon
							v-else
							color="error">
							mdi-close
						</v-icon>
					</template>
					<template #[`item.actions`]="{ item }">
						<div class="d-flex flex-row justify-center">
							<v-tooltip
								bottom
								color="primary darken-2">
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="22"
										v-bind="attrs"
										v-on="on"
										@click.stop="onRowClick(item)">
										mdi-alpha-i-circle
									</v-icon>
								</template>
								<span>Detail</span>
							</v-tooltip>
							<v-tooltip
								bottom
								color="primary darken-2">
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="22"
										v-bind="attrs"
										v-on="on"
										@click.stop="linkHistory(item._link)">
										mdi-alpha-d-circle
									</v-icon>
								</template>
								<span>Round detail</span>
							</v-tooltip>
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialog">
			<RoundDetailModal
				:item="selectedItem"
				:key="selectedItem"
				:currency="selectedItem?.currency"
				@openDetail="linkHistory(selectedItem._link)"
				@close="dialog = false"></RoundDetailModal>
		</v-dialog>
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				delimiter=";"
				builder="object"
				:data="roundsExportData"
				:filename="exportFilename"
				ref="roundsExport"></ExportDialog>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CardHeading from "../../components/shared/CardHeading.vue";
import RoundDetailModal from "../../components/rounds/roundDetailModal.vue";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import table from "../../mixins/table";
import permissions from "../../mixins/permissions";
import { shouldShortenString } from "../../constants/helpers";
import roundSearch from "../../store/modules/rounds/roundSearch";
import { ROUND_FLAGS } from "../../constants/constants";

export default {
	components: { CardHeading, RoundDetailModal, ExportDialog },
	mixins: [table, permissions],
	data() {
		return {
			search: "",
			options: {},
			exportDialog: false,
			selectedItem: undefined,
			dialog: false,
			ROUND_FLAGS,
			headers: [
				{ text: "Search Key", value: "search_key", align: "center" },
				{ text: "Found in", value: "match_key", align: "center" },
				{ text: "Round ID", value: "round_id", align: "center" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{
					text: "External Round",
					value: "external_round",
					align: "center",
					hide: () =>
						this.roundSearch.data?.every((el) => el.external_round === null),
				},
				{ text: "External Bet", value: "external_bet", align: "center" },
				{ text: "External Win", value: "external_win", align: "center" },
				{ text: "Bet", value: "bet", align: "right" },
				{ text: "Win", value: "win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				{
					text: "Finished",
					value: "finished",
					align: "center",
				},
				{
					text: "Flags",
					value: "flags",
					align: "center",
					sortable: false,
				},
				{
					text: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
				},
			],
		};
	},
	computed: {
		exportFilename() {
			return `rounds-${new Date().toLocaleString(undefined, {
				month: "2-digit",
				day: "2-digit",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})}`;
		},
		filteredData() {
			if (!this.roundSearch.data) return [];
			if (!this.search) return this.roundSearch.data;
			return this.roundSearch.data.filter((el) => {
				return (
					this.$options.filters
						.Capitalize(el.match_key.replace("_", " "))
						.includes(this.search) ||
					this.$options.filters.Date(el.start_time)?.includes(this.search) ||
					this.$options.filters
						.Number(el.bet, {
							currency: el.currency,
						})
						?.includes(this.search) ||
					this.$options.filters
						.Number(el.win, {
							currency: el.currency,
						})
						?.includes(this.search) ||
					this.$options.filters
						.Number(el.netto, {
							currency: el.currency,
						})
						?.includes(this.search) ||
					el.external_win?.includes(this.search) ||
					el.external_bet?.includes(this.search) ||
					el.external_round?.includes(this.search) ||
					el.round_id?.toString()?.includes(this.search)
				);
			});
		},
		...mapGetters(["roundSearch"]),
		roundsExportData() {
			return [
				[
					["search_key", "Search key"],
					["match_key", "Match key"],
					["round_id", "Round id"],
					["start_time", "Start time"],
					["external_round", "External round"],
					["external_bet", "External bet"],
					["external_win", "External win"],
					["bet", "Bet"],
					["win", "Win"],
					["netto", "Netto"],
					["currency", "Currency"],
					["finished", "Finished"],
				],
				this.roundSearch?.data.map((el) => {
					return {
						...el,
						match_key: this.$options.filters.Capitalize(
							el.match_key.replace("_", " ")
						),
						start_time: this.$options.filters.Date(el.start_time),
						bet: this.$options.filters.Number(el.bet, {
							style: "decimal",
							minimumFractionDigits: el.minor_units,
							maximumFractionDigits: el.minor_units,
						}),
						win: this.$options.filters.Number(el.win, {
							style: "decimal",
							minimumFractionDigits: el.minor_units,
							maximumFractionDigits: el.minor_units,
						}),
						netto: this.$options.filters.Number(el.netto, {
							style: "decimal",
							minimumFractionDigits: el.minor_units,
							maximumFractionDigits: el.minor_units,
						}),
					};
				}),
			];
		},
		filteredHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
	},

	methods: {
		itemClass() {
			return `table-item table-item--clickable`;
		},
		searchInId(str) {
			if (this.search && this.search != "" && str.includes(this.search)) {
				return this.$options.filters.highlight(str, this.search);
			} else {
				return this.$options.filters.ellipsis(str);
			}
		},
		handleRowClick(item, _, e) {
			if (
				!this._usrFlagsSome({
					key: "session.detail",
					val: this.permissions.READ,
				})
			)
				return;

			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "session-detail",
					params: {
						session_id: item.session_mongo_id,
						breadcrumbs: {
							title: `Session ${item.session_mongo_id}`,
							link: "/sessions/{session_id}",
							name: "session-detail",
						},
						query: {
							highlightId: item.round_id,
						},
					},
					query: {
						highlightId: item.round_id,
					},
				});
				window.open(tempRoute.href);
			} else {
				this.$router.push({
					name: "session-detail",
					params: {
						session_id: item.session_mongo_id,
						breadcrumbs: {
							title: `Session ${item.session_mongo_id}`,
							link: "/sessions/{session_id}",
							name: "session-detail",
						},
						query: {
							highlightId: item.round_id,
						},
					},
					query: {
						highlightId: item.round_id,
					},
				});
			}
		},
		onExportOpen() {
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.roundsExport.onExportOpen();
			}, 500);
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.roundsExport.onClose();
		},
		linkHistory(linkConfig) {
			window.open(...linkConfig);
		},
		onRowClick(e) {
			this.selectedItem = e;
			this.dialog = true;
		},
		...mapActions([
			"loadRoundSearch",
			"loadBulkRoundSearch",
			"loadSessionSearch",
			"loadPlayerSearch",
		]),
		...mapMutations(["setRoundSearchData"]),
		shouldShortenString,
	},
	async created() {
		this.setRoundSearchData([]);
		const searchKeys =
			this.$route.params?.searchKeys ??
			JSON.parse(sessionStorage.getItem("roundSearchKeys") ?? `[]`);
		const searchBy =
			this.$route.params?.searchBy ??
			JSON.parse(sessionStorage.getItem("roundSearchBy") ?? `[]`);
		sessionStorage.setItem("roundSearchKeys", JSON.stringify(searchKeys));
		sessionStorage.setItem("roundSearchBy", JSON.stringify(searchBy));
		await this.loadBulkRoundSearch({
			searchKeys: searchKeys,
			searchBy: searchBy,
		});
	},
	watch: {},
};
</script>

<style scoped>
::v-deep .v-dialog {
	overflow-y: visible !important;
}

::v-deep .highlight {
	color: var(--v-primary-base) !important;
	font-weight: bold;
}
</style>
