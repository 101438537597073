var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "RoundList", fluid: "", tag: "section" } },
    [
      _c(
        "v-card",
        { staticClass: "v-card--material pa-3 px-5 py-3" },
        [
          _c("CardHeading", { attrs: { title: "Round List" } }, [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-text-field", {
                  staticClass: "mr-5",
                  attrs: { clearable: "", "prepend-icon": "mdi-magnify" },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "primary darken-2" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.roundSearch.isLoading &&
                                        _vm.roundSearch.data.length > 0,
                                      expression:
                                        "!roundSearch.isLoading && roundSearch.data.length > 0",
                                    },
                                  ],
                                  attrs: {
                                    elevation: "1",
                                    fab: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.onExportOpen },
                                },
                                tooltip
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-arrow-down-bold-circle"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v("Export rounds")])]
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  "must-sort": "",
                  options: _vm.options,
                  "item-class": _vm.itemClass,
                  loading: _vm.roundSearch.isLoading,
                  "sort-by": "start_time",
                  "sort-desc": true,
                  "footer-props": _vm.footerProps,
                  headers: _vm.filteredHeaders,
                  items: _vm.filteredData,
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                  "click:row": _vm.handleRowClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function ({ pagination }) {
                        return [
                          _c(
                            "v-data-footer",
                            _vm._b(
                              {
                                staticClass: "anton",
                                attrs: {
                                  options: _vm.options,
                                  pagination: pagination,
                                },
                                on: {
                                  "update:options": function ($event) {
                                    _vm.options = $event
                                  },
                                },
                              },
                              "v-data-footer",
                              _vm.footerProps,
                              false
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.copy`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { fab: "", text: "", "x-small": "" },
                              on: { click: (e) => _vm.copyLine(e, item) },
                            },
                            [
                              _c("v-icon", { attrs: { color: "kajot-text" } }, [
                                _vm._v("mdi-content-copy"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.flags`,
                      fn: function ({ item }) {
                        return [
                          _vm._l(item.flags, function (flag) {
                            return [
                              _vm.ROUND_FLAGS[flag]
                                ? _c(
                                    "v-tooltip",
                                    {
                                      key: flag,
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-avatar",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mx-1",
                                                        attrs: {
                                                          size: "30",
                                                          color: "primary",
                                                        },
                                                      },
                                                      "v-avatar",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold kajot-anti-text--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ROUND_FLAGS[
                                                                flag
                                                              ].title
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.ROUND_FLAGS[flag].tooltip)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.external_round`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            attrs: {
                              title: _vm.shouldShortenString(
                                item.external_round
                              )
                                ? item.external_round
                                : undefined,
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.searchInId(item.external_round)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.external_bet`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            attrs: {
                              title: _vm.shouldShortenString(item.external_bet)
                                ? item.external_bet
                                : undefined,
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.searchInId(item.external_bet)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.external_win`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            attrs: {
                              title: _vm.shouldShortenString(item.external_win)
                                ? item.external_win
                                : undefined,
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.searchInId(item.external_win)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.start_time`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  _vm.$options.filters.Date(item.start_time),
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.round_id`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  item.round_id,
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.bet`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  _vm.$options.filters.Number(item.bet, {
                                    currency: item.currency,
                                  }),
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.win`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  _vm.$options.filters.Number(item.win, {
                                    currency: item.currency,
                                  }),
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.netto`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  _vm.$options.filters.Number(item.netto, {
                                    currency: item.currency,
                                  }),
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.match_key`,
                      fn: function ({ item }) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.highlight(
                                  _vm.$options.filters.Capitalize(
                                    item.match_key.replace("_", " ")
                                  ),
                                  _vm.search
                                )
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.finished`,
                      fn: function ({ item }) {
                        return [
                          item.finished
                            ? _c(
                                "v-icon",
                                { attrs: { color: "primary lighten-1" } },
                                [_vm._v(" mdi-check ")]
                              )
                            : _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v(" mdi-close "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row justify-center" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "primary darken-2",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      size: "22",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.onRowClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-alpha-i-circle ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Detail")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    color: "primary darken-2",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      size: "22",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.linkHistory(
                                                          item._link
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-alpha-d-circle ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Round detail")])]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c("RoundDetailModal", {
            key: _vm.selectedItem,
            attrs: {
              item: _vm.selectedItem,
              currency: _vm.selectedItem?.currency,
            },
            on: {
              openDetail: function ($event) {
                return _vm.linkHistory(_vm.selectedItem._link)
              },
              close: function ($event) {
                _vm.dialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "roundsExport",
            attrs: {
              delimiter: ";",
              builder: "object",
              data: _vm.roundsExportData,
              filename: _vm.exportFilename,
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }